define("discourse/plugins/discourse-multilingual/discourse/templates/components/content-tag-groups-form", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="tag-group-content">
    <h1>{{this.model.name}}</h1>
    <br />
  
    <section class="group-tags-list">
      <ul>
        {{#each this.buffered.tag_names as |name|}}
          <li>
            <span>{{name}}</span>
            <span>({{discourse-tag name contentLanguageTag=true}})</span>
          </li>
        {{/each}}
      </ul>
    </section>
  
    {{d-button
      class="btn-danger"
      action=(action "destroy")
      disabled=this.changingContentTags
      icon="far-trash-can"
      label="tagging.groups.delete"
    }}
  
    <div class="content-tag-controls">
      {{#if this.changingContentTags}}
        {{loading-spinner size="small"}}
        <span>{{this.changingContentTags}}</span>
      {{/if}}
  
      {{d-button
        class="btn-default"
        action=(action "updateContentTags")
        disabled=this.changingContentTags
        label="tagging.groups.content_tags.update.btn"
      }}
  
      {{d-button
        class="btn-danger"
        action=(action "destroyContentTags")
        icon="far-trash-can"
        disabled=this.changingContentTags
        label="tagging.groups.content_tags.delete.btn"
      }}
    </div>
  </div>
  */
  {
    "id": "Kg3de/ZT",
    "block": "[[[10,0],[14,0,\"tag-group-content\"],[12],[1,\"\\n  \"],[10,\"h1\"],[12],[1,[30,0,[\"model\",\"name\"]]],[13],[1,\"\\n  \"],[10,\"br\"],[12],[13],[1,\"\\n\\n  \"],[10,\"section\"],[14,0,\"group-tags-list\"],[12],[1,\"\\n    \"],[10,\"ul\"],[12],[1,\"\\n\"],[42,[28,[37,6],[[28,[37,6],[[30,0,[\"buffered\",\"tag_names\"]]],null]],null],null,[[[1,\"        \"],[10,\"li\"],[12],[1,\"\\n          \"],[10,1],[12],[1,[30,1]],[13],[1,\"\\n          \"],[10,1],[12],[1,\"(\"],[1,[28,[35,9],[[30,1]],[[\"contentLanguageTag\"],[true]]]],[1,\")\"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[1]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[1,[28,[35,10],null,[[\"class\",\"action\",\"disabled\",\"icon\",\"label\"],[\"btn-danger\",[28,[37,11],[[30,0],\"destroy\"],null],[30,0,[\"changingContentTags\"]],\"far-trash-can\",\"tagging.groups.delete\"]]]],[1,\"\\n\\n  \"],[10,0],[14,0,\"content-tag-controls\"],[12],[1,\"\\n\"],[41,[30,0,[\"changingContentTags\"]],[[[1,\"      \"],[1,[28,[35,13],null,[[\"size\"],[\"small\"]]]],[1,\"\\n      \"],[10,1],[12],[1,[30,0,[\"changingContentTags\"]]],[13],[1,\"\\n\"]],[]],null],[1,\"\\n    \"],[1,[28,[35,10],null,[[\"class\",\"action\",\"disabled\",\"label\"],[\"btn-default\",[28,[37,11],[[30,0],\"updateContentTags\"],null],[30,0,[\"changingContentTags\"]],\"tagging.groups.content_tags.update.btn\"]]]],[1,\"\\n\\n    \"],[1,[28,[35,10],null,[[\"class\",\"action\",\"icon\",\"disabled\",\"label\"],[\"btn-danger\",[28,[37,11],[[30,0],\"destroyContentTags\"],null],\"far-trash-can\",[30,0,[\"changingContentTags\"]],\"tagging.groups.content_tags.delete.btn\"]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"name\"],false,[\"div\",\"h1\",\"br\",\"section\",\"ul\",\"each\",\"-track-array\",\"li\",\"span\",\"discourse-tag\",\"d-button\",\"action\",\"if\",\"loading-spinner\"]]",
    "moduleName": "discourse/plugins/discourse-multilingual/discourse/templates/components/content-tag-groups-form.hbs",
    "isStrictMode": false
  });
});